import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Card, CardContent } from "@material-ui/core";
import { create_dataset } from "./../util/kiko_api";
import { useEffect } from "react";
import { useAuth } from "./../util/auth";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * @typedef Dataset
 * @property {string} dataset_id
 * @property {string} dataset_name
 * @property {string} table_description
 * @property {string} dataset_type
 * @property {string} table_name
 * @property {string} user_id
 * @property {Array<Column>} columns // optional
 */
/**
 * @typedef Column
 * @property {string} column_name
 * @property {string} column_type
 * @property {string} column_example
 * @property {string} column_id
 */

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // display: "flex",
    height: 800,
    overflowY: "auto",
    "& .MuiTextField-root": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiSelect-outlined": {
      // margin: theme.spacing(),
      minWidth: 120,
      // height: 10,
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const validationSchema = yup.object({
  dataset_name: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be 20 characters or less"),
  table_description: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(200, "Must be 200 characters or less"),
  // dataset_type: yup.string().required("Required"),
  table_name: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be 20 characters or less"),

  // Add validation for columns.  Columns is array of type Column
  columns: yup
    .array()
    .of(
      yup.object({
        column_name: yup
          .string()
          .required("Required")
          .min(2, "Must be 3 characters or more")
          .max(20, "Must be 20 characters or less"),
        column_type: yup
          .string()
          .required("Required")
          .min(3, "Must be 3 characters or more")
          .max(20, "Must be 20 characters or less"),
        column_example: yup
          .string()
          .required("Required")
          .min(1, "Must be 3 characters or more")
          .max(20, "Must be 20 characters or less"),
        // column_id: yup.string().required("Required"),
      })
    )
    .min(1, "Must have at least one column"),
});

function AddDataset(props) {
  const classes = useStyles();
  const auth = useAuth();
  let history = useHistory();

  /**
   * @typedef {Dataset} dataset_info
   * @description A dataset object
   */
  /**
   * @typedef {Function} setDatasetInfo
   * @description A function that sets the dataset info
   */
  /**
   * @type {[dataset_info, setDatasetInfo]} dataset_info
   */
  // const [dataset_info, setDatasetInfo] = React.useState();

  const formik = useFormik({
    initialValues: {
      dataset_name: "",
      dataset_id: "",
      table_name: "",
      table_description: "",
      columns: [{ column_name: "", column_type: "", column_example: "" }],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      handleDatasetCreate();
    },
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle updating the dataset info
  const handleDatasetCreate = () => {
    var auth_token = auth.user.accessToken;
    var dataset = formik.values;
    // console.log("CREATE DATASET", dataset);
    create_dataset(dataset, auth_token).then((data) => {
      var dataset_id = data.dataset_id;

      console.log(data);
      // redirect to /dataset/:dataset_id
      if (dataset_id) {
        history.push("/dataset/" + dataset_id);
      } else {
        setOpen(true);
      }
      // props.history.push(`/dataset/${dataset_id}`);
      // this.props.router.push("/dataset/" + dataset_id);
    });
  };

  return (
    <Grid xs={8} md={8} item={true}>
      <Card elevation={20}>
        <div className={classes.root}>
          <CardContent>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div noValidate autoComplete="off">
                  <Container>
                    <Typography variant="h6" gutterBottom>
                      Dataset Info
                    </Typography>
                    {/* <Box spacing={2}> */}
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="dataset_name"
                          name="dataset_name"
                          label="Dataset Name"
                          variant="outlined"
                          value={formik.values.dataset_name}
                          onChange={formik.handleChange}
                          error={formik.errors.dataset_name ? true : false}
                          helperText={formik.errors.dataset_name}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="table_name"
                          name="table_name"
                          label="Table Name"
                          variant="outlined"
                          value={formik.values.table_name}
                          onChange={formik.handleChange}
                          error={formik.errors.table_name ? true : false}
                          helperText={formik.errors.table_name}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="table_description"
                          name="table_description"
                          label="Table Description"
                          multiline
                          rows={4}
                          // fullWidth
                          variant="outlined"
                          value={formik.values.table_description}
                          onChange={formik.handleChange}
                          error={formik.errors.table_description ? true : false}
                          helperText={formik.errors.table_description}
                        />
                      </Grid>
                    </Grid>

                    <Box style={{ marginTop: "20px" }}>
                      <Typography variant="h6" gutterBottom>
                        Columns
                      </Typography>
                      {formik.values.columns.map((column, index) => (
                        <Grid spacing={2} container={true} key={index}>
                          <Grid item={true} xs={1}>
                            {/* onclick of IconButton, remove the corresponding column index from formik */}
                            <IconButton
                              onClick={() => {
                                formik.values.columns.splice(index, 1);
                                formik.setFieldValue(
                                  "columns",
                                  formik.values.columns
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item={true} xs={4}>
                            <TextField
                              style={{
                                width: "100%",
                              }}
                              variant="outlined"
                              type="text"
                              name="message"
                              multiline={true}
                              label="Column Name"
                              rows={1}
                              fullWidth={true}
                              value={column.column_name}
                              onChange={(event) => {
                                formik.values.columns[index].column_name =
                                  event.target.value;
                                formik.setFieldValue(
                                  "columns",
                                  formik.values.columns
                                );
                              }}
                              error={
                                formik.errors.columns && formik.touched.columns
                              }
                            />
                          </Grid>
                          <Grid item={true} xs={4}>
                            <TextField
                              style={{
                                width: "100%",
                              }}
                              variant="outlined"
                              type="text"
                              name="message"
                              multiline={true}
                              label="Column Example"
                              rows={1}
                              fullWidth={true}
                              value={column.column_example}
                              onChange={(event) => {
                                formik.values.columns[index].column_example =
                                  event.target.value;
                                formik.setFieldValue(
                                  "columns",
                                  formik.values.columns
                                );
                              }}
                              error={
                                formik.errors.columns && formik.touched.columns
                              }
                            />
                          </Grid>
                          <Grid item={true} xs={3}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Column Type
                              </InputLabel>
                              <Select
                                style={{
                                  width: "100%",
                                }}
                                variant="outlined"
                                label="Column Type"
                                name="Column Type"
                                type="options"
                                fullWidth={true}
                                value={column.column_type}
                                onChange={(event) => {
                                  formik.values.columns[index].column_type =
                                    event.target.value;
                                  formik.setFieldValue(
                                    "columns",
                                    formik.values.columns
                                  );
                                }}
                              >
                                <MenuItem value="boolean">Boolean</MenuItem>
                                <MenuItem value="date">Date</MenuItem>
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ))}
                      {/* Add button "Add Column" */}
                      <Grid container={true} spacing={2}>
                        <Grid item={true}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              formik.values.columns.push({
                                column_name: "",
                                column_example: "",
                                column_type: "text",
                                column_id: "",
                              });
                              console.log(formik.values.columns);
                              formik.setFieldValue(
                                "columns",
                                formik.values.columns
                              );
                            }}
                          >
                            + Add Column
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* Add submit bottom at the very bottom */}
                    <Box style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth={true}
                        disabled={!formik.isValid}
                      >
                        Create New Dataset
                      </Button>
                      {formik.isSubmitting && <CircularProgress />}
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          Dataset couldn't be created. You might already have 5
                          datasets (which is the limit).
                        </Alert>
                      </Snackbar>
                    </Box>
                  </Container>
                </div>
              </form>
            </div>
          </CardContent>
        </div>
      </Card>
    </Grid>
  );
}

export default AddDataset;
