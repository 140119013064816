import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import BackupIcon from "@material-ui/icons/Backup";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import { TwitterTweetEmbed } from "react-twitter-embed";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}));

function FeaturesSection2(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Add Your Own Data",
      description: "Upload your dataset's schema.",
      icon: BackupIcon,
      iconColor: "primary.main",
    },
    {
      title: "Ask Questions",
      description: "Ask whatever questions you need to know in plain-English.",
      icon: QuestionAnswerIcon,
      iconColor: "secondary.main",
    },
    {
      title: "Get Answers",
      description:
        "In just a few seconds, Kiko translates your questions into the corresponding SQL & Pandas syntax.",
      icon: SubtitlesIcon,
      iconColor: "#00d1b2",
    },
  ];

  return (
    <div>
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container className={classes.container}>
          <Grid container={true} alignItems="center" spacing={8}>
            <Grid
              container={true}
              item={true}
              direction="column"
              xs={12}
              md={6}
            >
              <figure className={classes.imageWrapper}>
                <img src={props.image} alt="" />
              </figure>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              {items.map((item, index) => (
                <Grid
                  className={classes.row}
                  item={true}
                  container={true}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={5}
                  key={index}
                >
                  <Grid item={true} xs="auto">
                    <Box
                      color={item.iconColor}
                      display="flex"
                      justifyContent="center"
                      fontSize={70}
                      width={70}
                      height={70}
                    >
                      <item.icon fontSize="inherit" />
                    </Box>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Typography variant="h5" gutterBottom={true}>
                      {item.title}
                    </Typography>
                    <Typography variant="subtitle1">
                      {item.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bgColor={props.bgColor} size={props.size}>
        <Container className={classes.container}>
          <Grid container={true} spacing={5}>
            <Grid item={true} xs={12}>
              <Typography variant="h4" gutterBottom={true}>
                How it works
              </Typography>
            </Grid>
          </Grid>

          <Grid container={true} alignItems="center" spacing={8}>
            {[
              "1484046311833939969",
              "1484940881283141634",
              "1462966258366885893",
            ].map((id) => (
              <Grid
                container={true}
                item={true}
                direction="column"
                xs={12}
                md={4}
                key={id}
              >
                <TwitterTweetEmbed
                  tweetId={id}
                  theme="dark"
                  options={{ theme: "dark" }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
    </div>
  );
}

export default FeaturesSection2;
