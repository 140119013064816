import React from "react";
import Meta from "../components/Meta";
import DatasetSection from "../components/DatasetSection";
import { requireAuth } from "../util/auth";
import { useRouter } from "./../util/router";

function DatasetPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Dataset" />
      <DatasetSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        dataset_id={router.query.dataset_id}
        // title="Your Datasets"
        // subtitle="All of the datasets you have added to your account."
      />
    </>
  );
}

export default requireAuth(DatasetPage);
