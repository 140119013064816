import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  get_dataset_info,
  update_dataset_info,
  delete_dataset,
} from "./../util/kiko_api";
import { useEffect } from "react";
import { useAuth } from "./../util/auth";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";

/**
 * @typedef Dataset
 * @property {string} dataset_id
 * @property {string} dataset_name
 * @property {string} table_description
 * @property {string} dataset_type
 * @property {string} table_name
 * @property {string} user_id
 * @property {Array<Column>} columns // optional
 */
/**
 * @typedef Column
 * @property {string} column_name
 * @property {string} column_type
 * @property {string} column_example
 * @property {string} column_id
 */

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 600,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiSelect-outlined": {
      margin: theme.spacing(0.5),
      // minWidth: 120,
      // height: 10,
    },
  },
}));

// Typewriter DOCS: https://www.npmjs.com/package/react-simple-typewriter
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validationSchema = yup.object({
  dataset_name: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be 20 characters or less"),
  table_description: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(200, "Must be 200 characters or less"),
  // dataset_type: yup.string().required("Required"),
  table_name: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be 20 characters or less"),

  // Add validation for columns.  Columns is array of type Column
  columns: yup
    .array()
    .of(
      yup.object({
        column_name: yup
          .string()
          .required("Required")
          .min(1, "Must be 1 character or more")
          .max(20, "Must be 20 characters or less"),
        column_type: yup
          .string()
          .required("Required")
          .min(1, "Must be 1 character or more")
          .max(20, "Must be 20 characters or less"),
        column_example: yup
          .string()
          .required("Required")
          .min(1, "Must be 1 character or more")
          .max(20, "Must be 20 characters or less"),
        // column_id: yup.string().required("Required"),
      })
    )
    .min(1, "Must have at least one column"),
});

function DatasetInfo(props) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  const auth = useAuth();
  let history = useHistory();

  const dataset_id = props.dataset_id;

  /**
   * @typedef {Dataset} dataset_info
   * @description A dataset object
   */
  /**
   * @typedef {Function} setDatasetInfo
   * @description A function that sets the dataset info
   */
  /**
   * @type {[dataset_info, setDatasetInfo]} dataset_info
   */
  const [dataset_info, setDatasetInfo] = React.useState();

  const formik = useFormik({
    initialValues: {
      dataset_name: "",
      dataset_id: "",
      table_name: "",
      table_description: "",
      columns: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return handleDatasetUpdate();
    },
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  useEffect(() => {
    var auth_token = auth.user.accessToken;

    get_dataset_info(dataset_id, auth_token).then((data) => {
      var dataset_info = data;

      // Set dataset_info to formik
      formik.setFieldValue("dataset_name", dataset_info.dataset_name);
      formik.setFieldValue("table_name", dataset_info.table_name);
      formik.setFieldValue("table_description", dataset_info.table_description);
      formik.setFieldValue("columns", dataset_info.columns);
      formik.setFieldValue("dataset_id", dataset_info.dataset_id);
      setDatasetInfo(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle updating the dataset info
  const handleDatasetUpdate = () => {
    var auth_token = auth.user.accessToken;
    var dataset_info = formik.values;
    return update_dataset_info(dataset_info, auth_token).then((data) => {
      setOpen(true);
      return "DONE";
    });
  };

  // handle deleting the dataset
  const handleDeleteDataset = () => {
    var auth_token = auth.user.accessToken;

    delete_dataset(dataset_id, auth_token).then((data) => {
      history.push("/dashboard");
    });
  };

  return (
    <div>
      {dataset_info ? (
        <form onSubmit={formik.handleSubmit}>
          <div noValidate autoComplete="off">
            <Container>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Dataset Info
                </Typography>
                <TextField
                  id="dataset_name"
                  name="dataset_name"
                  label="Dataset Name"
                  variant="outlined"
                  value={formik.values.dataset_name}
                  onChange={formik.handleChange}
                  error={formik.errors.dataset_name ? true : false}
                  helperText={formik.errors.dataset_name}
                />
                <TextField
                  id="table_name"
                  name="table_name"
                  label="Table Name"
                  variant="outlined"
                  value={formik.values.table_name}
                  onChange={formik.handleChange}
                  error={formik.errors.table_name ? true : false}
                  helperText={formik.errors.table_name}
                />
              </Box>
              <Box>
                <TextField
                  style={{ width: "400px" }}
                  id="table_description"
                  name="table_description"
                  label="Table Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={formik.values.table_description}
                  onChange={formik.handleChange}
                  error={formik.errors.table_description ? true : false}
                  helperText={formik.errors.table_description}
                />
              </Box>
              <Box style={{ marginTop: "20px", width: 700 }}>
                <Typography variant="h6" gutterBottom>
                  Columns
                </Typography>
                {formik.values.columns.map((column, index) => (
                  <Grid spacing={2} container={true} key={index}>
                    <Grid item={true} xs={1}>
                      {/* onclick of IconButton, remove the corresponding column index from formik */}
                      <IconButton
                        onClick={() => {
                          formik.values.columns.splice(index, 1);
                          formik.setFieldValue(
                            "columns",
                            formik.values.columns
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        variant="outlined"
                        type="text"
                        name="message"
                        multiline={true}
                        label="Column Name"
                        rows={1}
                        fullWidth={true}
                        value={column.column_name}
                        onChange={(event) => {
                          formik.values.columns[index].column_name =
                            event.target.value;
                          formik.setFieldValue(
                            "columns",
                            formik.values.columns
                          );
                        }}
                        error={formik.errors.columns && formik.touched.columns}
                        helperText={
                          formik.errors.columns && formik.touched.columns
                            ? formik.errors.columns
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        variant="outlined"
                        type="text"
                        name="message"
                        multiline={true}
                        label="Column Example"
                        rows={1}
                        fullWidth={true}
                        value={column.column_example}
                        onChange={(event) => {
                          formik.values.columns[index].column_example =
                            event.target.value;
                          formik.setFieldValue(
                            "columns",
                            formik.values.columns
                          );
                        }}
                        error={formik.errors.columns && formik.touched.columns}
                        helperText={
                          formik.errors.columns && formik.touched.columns
                            ? formik.errors.columns
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={3}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Column Type
                        </InputLabel>
                        <Select
                          variant="outlined"
                          label="Column Type"
                          name="Column Type"
                          type="options"
                          fullWidth={true}
                          value={column.column_type}
                          onChange={(event) => {
                            formik.values.columns[index].column_type =
                              event.target.value;
                            formik.setFieldValue(
                              "columns",
                              formik.values.columns
                            );
                          }}
                          error={
                            formik.errors.columns && formik.touched.columns
                          }
                          helperText={
                            formik.errors.columns && formik.touched.columns
                              ? formik.errors.columns
                              : ""
                          }
                        >
                          <MenuItem value="boolean">Boolean</MenuItem>
                          <MenuItem value="date">Date</MenuItem>
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
                {/* Add button "Add Column" */}
                <Grid container={true} spacing={2}>
                  <Grid item={true}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        formik.values.columns.push({
                          column_name: "",
                          column_example: "",
                          column_type: "",
                          column_id: "",
                        });
                        formik.setFieldValue("columns", formik.values.columns);
                      }}
                    >
                      Add Column
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {/* Add submit bottom at the very bottom */}
              <Box style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth={true}
                  disabled={!formik.isValid}
                >
                  Save
                </Button>
                {formik.isSubmitting && <CircularProgress />}

                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    🎉 Dataset Info Updated!
                  </Alert>
                </Snackbar>
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  onClick={handleDeleteDataset}
                >
                  Delete Dataset
                </Button>
              </Box>
            </Container>
          </div>
        </form>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default DatasetInfo;
