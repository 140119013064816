import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AddDataset from "../components/AddDataset";
import { Link } from "./../util/router";
import { Button } from "@material-ui/core";

// // const useStyles = makeStyles((theme) => ({
//   map: {
//     border: 0,
//     width: "100%",
//     height: "320px",
//     backgroundColor: "#efefef",
//     // Use some CSS to give the embedded map a dark mode
//     // since Google Maps embeds don't support it yet.
//     filter: theme.palette.type === "dark" && "invert(90%)",
//   },
// }));

function ContactSection2(props) {
  // const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="lg">
        <Button component={Link} to={"/dashboard"}>
          ⬅️ View All Datasets
        </Button>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} spacing={4}>
          <AddDataset />
          <Grid xs={4} md={4} item={true}>
            <Card>
              <CardContent>
                <Typography>
                  At this time, you can only have 5 datasets per account. You
                  will be able to add more datasets soon.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default ContactSection2;
