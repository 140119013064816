import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Card, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { translate_query, translate_query_pandas } from "../util/kiko_api";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useTypewriter } from "react-simple-typewriter";
import FadeIn from "react-fade-in";
import { useFormik } from "formik";
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";

// Typewriter DOCS: https://www.npmjs.com/package/react-simple-typewriter
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "30ch",
    },
  },
}));

const validationSchema = yup.object({
  english_question: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(100, "Must be 100 characters or less"),
});

function HeroSection2(props) {
  const classes = useStyles();

  const { text } = useTypewriter({
    words: [
      "# Question:\n# Which employee made the most money in 2020?",
      "# SQL Answer: \nSELECT name FROM employees WHERE salary = (SELECT MAX(salary) FROM employees)",
      "# Question:\n# Which day had the lowest NASDAQ stock price and which had the highest?",
      "# SQL Answer: \nSELECT date, MIN(price), MAX(price) FROM nasdaq GROUP BY date ORDER BY date;",
    ],
    loop: 0,
    typeSpeed: 2,
    deleteSpeed: 1,
    delaySpeed: 3000,
  });
  // Create pending variable to track whether the query is being translated
  const [pending, setPending] = React.useState(false);
  // const [english_question, setEnglishQuestion] = React.useState("");
  const [sql_answer, setSqlAnswer] = React.useState("");
  const [pandas_answer, setPandasAnswer] = React.useState("");
  React.useState({
    sql_translation: "",
    status: "successful",
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [value, setValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const formik = useFormik({
    initialValues: {
      english_question: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      translate_english_question();
    },
  });
  var datasets = [
    {
      name: "Human Resources",
      id: "ZMQQNNDI2",
      sample_english_question: "Which employee made the most money in 2020?",
      sample_sql_answer:
        "SELECT name FROM employees WHERE salary = (SELECT MAX(salary) FROM employees)",
      sample_pandas_answer: `answer = df[(df['DateofHire'] >= '2020-01-01') & (df['DateofHire'] <= '2020-12-31')].sort_values(by='Salary', ascending=False).head(1)\nanswer`,
      columns: [
        {
          column_name: "Employee_Name",
          column_type: "text",
          column_example: "Foreman, Tanya",
          column_id: 248573,
        },
        {
          column_name: "EmpID",
          column_type: "number",
          column_example: 10116,
          column_id: 994592,
        },
        {
          column_name: "MarriedID",
          column_type: "number",
          column_example: 0,
          column_id: 459781,
        },
        {
          column_name: "MaritalStatusID",
          column_type: "number",
          column_example: 1,
          column_id: 331556,
        },
        {
          column_name: "GenderID",
          column_type: "number",
          column_example: 0,
          column_id: 586625,
        },
        {
          column_name: "EmpStatusID",
          column_type: "number",
          column_example: 5,
          column_id: 983993,
        },
        {
          column_name: "DeptID",
          column_type: "number",
          column_example: 3,
          column_id: 802454,
        },
        {
          column_name: "PerfScoreID",
          column_type: "number",
          column_example: 3,
          column_id: 46256,
        },
        {
          column_name: "FromDiversityJobFairID",
          column_type: "number",
          column_example: 0,
          column_id: 492249,
        },
        {
          column_name: "Salary",
          column_type: "number",
          column_example: 73330,
          column_id: 767262,
        },
        {
          column_name: "Termd",
          column_type: "number",
          column_example: 0,
          column_id: 387739,
        },
        {
          column_name: "PositionID",
          column_type: "number",
          column_example: 19,
          column_id: 809246,
        },
        {
          column_name: "Position",
          column_type: "text",
          column_example: "Sales Manager",
          column_id: 171733,
        },
        {
          column_name: "State",
          column_type: "text",
          column_example: "MA",
          column_id: 242620,
        },
        {
          column_name: "Zip",
          column_type: "number",
          column_example: 1550,
          column_id: 900146,
        },
        {
          column_name: "DOB",
          column_type: "text",
          column_example: "05/19/82",
          column_id: 137372,
        },
        {
          column_name: "Sex",
          column_type: "text",
          column_example: "F",
          column_id: 834428,
        },
        {
          column_name: "MaritalDesc",
          column_type: "text",
          column_example: "Divorced",
          column_id: 658526,
        },
        {
          column_name: "CitizenDesc",
          column_type: "text",
          column_example: "US Citizen",
          column_id: 627407,
        },
        {
          column_name: "HispanicLatino",
          column_type: "text",
          column_example: "Yes",
          column_id: 592605,
        },
        {
          column_name: "RaceDesc",
          column_type: "text",
          column_example: "Asian",
          column_id: 820263,
        },
        {
          column_name: "DateofHire",
          column_type: "text",
          column_example: "2/21/2011",
          column_id: 388681,
        },
        {
          column_name: "DateofTermination",
          column_type: "text",
          column_example: "4/24/2014",
          column_id: 859715,
        },
        {
          column_name: "TermReason",
          column_type: "text",
          column_example: "career change",
          column_id: 744986,
        },
        {
          column_name: "EmploymentStatus",
          column_type: "text",
          column_example: "Active",
          column_id: 965345,
        },
        {
          column_name: "Department",
          column_type: "text",
          column_example: "Production       ",
          column_id: 442601,
        },
        {
          column_name: "ManagerName",
          column_type: "text",
          column_example: "Lynn Daneault",
          column_id: 665324,
        },
        {
          column_name: "ManagerID",
          column_type: "number",
          column_example: 13,
          column_id: 299399,
        },
        {
          column_name: "RecruitmentSource",
          column_type: "text",
          column_example: "LinkedIn",
          column_id: 533598,
        },
        {
          column_name: "PerformanceScore",
          column_type: "text",
          column_example: "Fully Meets",
          column_id: 640256,
        },
        {
          column_name: "EngagementSurvey",
          column_type: "number",
          column_example: 5,
          column_id: 543287,
        },
        {
          column_name: "EmpSatisfaction",
          column_type: "number",
          column_example: 4,
          column_id: 406751,
        },
        {
          column_name: "SpecialProjectsCount",
          column_type: "number",
          column_example: 0,
          column_id: 258903,
        },
        {
          column_name: "LastPerformanceReview_Date",
          column_type: "text",
          column_example: "4/15/2015",
          column_id: 865985,
        },
        {
          column_name: "DaysLateLast30",
          column_type: "number",
          column_example: 0,
          column_id: 454058,
        },
        {
          column_name: "Absences",
          column_type: "number",
          column_example: 19,
          column_id: 673546,
        },
      ],
    },
    {
      name: "Stock Market (NASDAQ)",
      id: "NYDPVUX05",
      sample_english_question:
        "Which day had the lowest price and which had the highest?",
      sample_sql_answer:
        "SELECT date, MIN(price), MAX(price) FROM nasdaq GROUP BY date ORDER BY date;  ",
      sample_pandas_answer: `answer = df.groupby(['date'])['price'].max()\nprint(answer)\nanswer = df.groupby(['date'])['price'].min()\nprint(answer)`,
    },
  ];
  const [dataset, setDataset] = React.useState(datasets[0]);

  useEffect(() => {
    const set_example_question_answer = () => {
      var random_dataset_index = Math.floor(Math.random() * datasets.length);
      setDataset(datasets[random_dataset_index]);
      formik.values.english_question =
        datasets[random_dataset_index].sample_english_question;

      // setEnglishQuestion(
      //   datasets[random_dataset_index].sample_english_question
      // );
      setSqlAnswer(datasets[random_dataset_index].sample_sql_answer);
      setPandasAnswer(datasets[random_dataset_index].sample_pandas_answer);
    };
    set_example_question_answer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translate_english_question = () => {
    // Set pending to true
    var english_question = formik.values.english_question;
    setPending(true);
    setSqlAnswer("Loading...");
    translate_query(
      english_question,
      [dataset.id],
      "QYLTEFYNOZDXVSHSNMKKE8TYB",
      false,
      ""
    ).then((data) => {
      // Set pending to false
      setPending(false);
      setSqlAnswer(data.sql_translation);
    });

    translate_query_pandas(
      english_question,
      dataset.id,
      "QYLTEFYNOZDXVSHSNMKKE8TYB",
      false
    ).then((data) => {
      // Set pending to false
      setPending(false);
      // setKikoTranslationResponse(data);
      setPandasAnswer(data.pandas_translation);
    });
  };

  // Create function that copies the sql_answer to the clipboard
  const copy_sql_answer = () => {
    navigator.clipboard.writeText(sql_answer);
    setOpen(true);
  };

  const copy_pandas_answer = () => {
    navigator.clipboard.writeText(pandas_answer);
    setOpen(true);
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={5}>
          <Grid container={true} item={true} direction="column" xs={12} md={5}>
            <FadeIn>
              <Box textAlign={{ xs: "left", md: "left" }}>
                <SectionHeader
                  title={props.title}
                  subtitle={props.subtitle}
                  size={1}
                />
                <Typography variant="h6">
                  Powered by OpenAI's{" "}
                  <a href="https://openai.com/blog/openai-codex/">
                    GPT-3 & Codex
                  </a>{" "}
                  🧠
                </Typography>
                {/* Add padding at the bottom of box below */}
                <Box pt={3}>
                  <SyntaxHighlighter
                    language="sql"
                    style={nord}
                    wrapLines={true}
                    wrapLongLines={true}
                  >
                    {text}
                  </SyntaxHighlighter>
                </Box>
                <Box pt={3}>
                  <Button
                    component={Link}
                    to={props.buttonPath}
                    variant="contained"
                    size="large"
                    color={props.buttonColor}
                  >
                    {props.buttonText}
                  </Button>
                </Box>
              </Box>
            </FadeIn>
          </Grid>
          <Grid item={true} xs={12} md={7}>
            {/* Add large shadow to card */}
            <Card
              elevation={20}
              style={{
                width: "100%",
                height: "600px",
                overflowY: "scroll",
              }}
            >
              {pending && <LinearProgress />}

              <Box>
                <form className={classes.root} noValidate autoComplete="off">
                  <Chip
                    icon={<DoneIcon />}
                    label="Ask your own questions!"
                    clickable
                    color="primary"
                  />
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{
                      width: "90%",
                      height: "100%",
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Example Datasets
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={dataset.id}
                      // onChange={}
                      label={dataset.name}
                    >
                      {datasets.map((dataset) => (
                        <MenuItem
                          key={dataset.id}
                          value={dataset.id}
                          onClick={() => {
                            setDataset(dataset);
                            formik.values.english_question =
                              dataset.sample_english_question;
                            // setEnglishQuestion(dataset.sample_english_question);
                            setSqlAnswer(dataset.sample_sql_answer);
                            setPandasAnswer(dataset.sample_pandas_answer);
                          }}
                        >
                          {dataset.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    id="english_question"
                    name="english_question"
                    label="Question"
                    variant="outlined"
                    style={{
                      width: "90%",
                      height: "100%",
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.english_question}
                    error={formik.errors.english_question ? true : false}
                    helperText={formik.errors.english_question}
                  />
                  {/* Add Circular progress that is shown if "pending" variable is true. */}
                  {pending && <CircularProgress />}
                  {/* Hide button if "pending" is true */}
                  {!pending && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={translate_english_question}
                      disabled={!formik.isValid}
                    >
                      Translate Question
                    </Button>
                  )}
                </form>
              </Box>

              <TabContext value={value}>
                <AppBar position="static">
                  <TabList
                    onChange={handleTabChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="SQL Answer" value="1" />
                    <Tab label="Pandas Answer" value="2" />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <Box>
                    <SyntaxHighlighter
                      language="sql"
                      style={nord}
                      wrapLines={true}
                      wrapLongLines={true}
                    >
                      {"# SQL Translation\n" + sql_answer}
                    </SyntaxHighlighter>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={copy_sql_answer}
                    >
                      Copy to Clipboard
                    </Button>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert onClose={handleClose} severity="success">
                        🎉 SQL Code was successfully copied to your clipboard!
                      </Alert>
                    </Snackbar>
                  </Box>
                </TabPanel>
                <TabPanel value="2">
                  <Box>
                    <SyntaxHighlighter
                      language="python"
                      style={nord}
                      wrapLines={true}
                      wrapLongLines={true}
                    >
                      {`# Pandas Translation\nimport pandas as pd\ndf = pd.read_csv("<CSV_FILE_PATH>")\n\n` +
                        pandas_answer}
                    </SyntaxHighlighter>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={copy_pandas_answer}
                    >
                      Copy to Clipboard
                    </Button>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert onClose={handleClose} severity="success">
                        🎉 Pandas Code was successfully copied to your
                        clipboard!
                      </Alert>
                    </Snackbar>
                  </Box>
                </TabPanel>
              </TabContext>
              {/* Box should have width of 100% */}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection2;
