import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
// import NewsletterSection from "./../components/NewsletterSection";
import AddDatasetButton from "./../components/AddDatasetButton";
import FeaturesSection2 from "./../components/FeaturesSection2";
import FaqSection from "./../components/FaqSection";
// import FeaturesSection3 from "./../components/FeaturesSection3";

// import { useState, useEffect } from "react";

function IndexPage(props) {
  // create state
  // const [hero_subtitle, setHeroSubtitle] = useState("");
  // Create a function that animates typing a sentence like a typewriter in 3 seconds.

  return (
    <>
      <Meta />
      <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Kiko"
        // subtitle={hero_subtitle}
        subtitle="Translate English to SQL & Pandas in seconds."
        buttonText="Sign Up for Free"
        buttonColor="primary"
        buttonPath="/auth/signup"
      />
      <FeaturesSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        image="./translate.png"
      />
      {/* <FeaturesSection3
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="How It Works"
        subtitle="Kiko uses advanced artificial intelligence."
      /> */}
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />

      {/* <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Join the Waitlist"
        subtitle="Get notified when we launch"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now on the waitlist!"
      /> */}
      <AddDatasetButton
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/auth/signup"
      />
    </>
  );
}

export default IndexPage;
