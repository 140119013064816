import React from "react";
import Meta from "./../components/Meta";
import ContactSection2 from "./../components/ContactSection2";
function AddDatasetPage(props) {
  return (
    <>
      <Meta title="Add Dataset" />
      <ContactSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="🆕 Add Dataset"
        subtitle="Use this form to add a dataset to your account."
        buttonText="Add Dataset"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default AddDatasetPage;
