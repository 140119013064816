import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { Card, TextField } from "@material-ui/core";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useFormik } from "formik";
import * as yup from "yup";
import { translate_query, translate_query_pandas } from "../util/kiko_api";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IncludeDatasets from "./IncludeDatasets";
import { useAuth } from "./../util/auth";

// Typewriter DOCS: https://www.npmjs.com/package/react-simple-typewriter
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    // maxWidth: 570,
    display: "block",
    height: "auto",
    // width: "100%",
  },

  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "50%",
    },
  },
}));

const validationSchema = yup.object({
  english_question: yup
    .string()
    .required("Required")
    .min(3, "Must be 3 characters or more")
    .max(300, "Must be 300 characters or less"),
});

function DatasetPlayground(props) {
  const auth = useAuth();

  const classes = useStyles();

  const [includeDatasets, setIncludeDatasets] = React.useState(false);

  const [pending, setPending] = React.useState(false);
  const [sql_answer, setSqlAnswer] = React.useState("");
  const [pandas_answer, setPandasAnswer] = React.useState("");

  React.useState({
    sql_translation: "",
    status: "successful",
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [value, setValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleIncludeDatasets = () => {
    setIncludeDatasets((prev) => !prev);
    formik.setFieldValue("include_datasets", !includeDatasets);
  };
  const dataset = props.dataset_info;

  const formik = useFormik({
    initialValues: {
      english_question: "",
      dataset_ids: [dataset.dataset_id],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      translate_english_question();
    },
  });
  const translate_english_question = () => {
    var auth_token = auth.user.accessToken;
    var english_question = formik.values.english_question;

    setPending(true);
    setSqlAnswer("Loading...");
    translate_query(
      english_question,
      formik.values.dataset_ids,
      "",
      false,
      auth_token
    ).then((data) => {
      // Set pending to false
      setPending(false);
      setSqlAnswer(data.sql_translation);
    });

    translate_query_pandas(
      english_question,
      dataset.dataset_id,
      "",
      false,
      auth_token
    ).then((data) => {
      setPending(false);
      setPandasAnswer(data.pandas_translation);
    });
  };

  const copy_sql_answer = () => {
    navigator.clipboard.writeText(sql_answer);
    setOpen(true);
  };
  const copy_pandas_answer = () => {
    navigator.clipboard.writeText(pandas_answer);
    setOpen(true);
  };

  return (
    <Container className={classes.container}>
      <Grid container={true} alignItems="center" spacing={5}>
        <Grid item={true} xs={12} md={12}>
          <Card
            elevation={1}
            style={{
              // width: "100%",
              height: "600px",
              overflowY: "scroll",
            }}
          >
            {pending && <LinearProgress />}

            <Box>
              <form
                className={classes.root}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                style={{ width: "100%" }}
              >
                <Chip
                  icon={<DoneIcon />}
                  label="Ask your own questions!"
                  clickable
                  color="primary"
                />

                <TextField
                  id="english_question"
                  name="english_question"
                  label="Question"
                  variant="outlined"
                  style={{
                    width: "90%",
                    height: "100%",
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.english_question}
                  error={formik.errors.english_question ? true : false}
                  helperText={formik.errors.english_question}
                  rows={3}
                  multiline={true}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeDatasets}
                        onChange={toggleIncludeDatasets}
                      />
                    }
                    label="Include (JOIN) other datasets?"
                  />
                </FormGroup>
                {includeDatasets && (
                  <IncludeDatasets
                    dataset_id={dataset.dataset_id}
                    props={formik}
                  />
                )}

                {/* Add Circular progress that is shown if "pending" variable is true. */}
                {pending && <CircularProgress />}
                {/* Hide button if "pending" is true */}
                {!pending && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    Translate Question
                  </Button>
                )}
              </form>
            </Box>

            <TabContext value={value}>
              <AppBar position="static">
                <TabList
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="SQL Answer" value="1" />
                  <Tab label="Pandas Answer" value="2" />
                </TabList>
              </AppBar>
              <TabPanel value="1">
                <Box>
                  <SyntaxHighlighter
                    language="sql"
                    style={nord}
                    wrapLines={true}
                    wrapLongLines={true}
                  >
                    {"# SQL Translation\n" + sql_answer}
                  </SyntaxHighlighter>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={copy_sql_answer}
                  >
                    Copy to Clipboard
                  </Button>
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="success">
                      🎉 SQL Code was successfully copied to your clipboard!
                    </Alert>
                  </Snackbar>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box>
                  <SyntaxHighlighter
                    language="python"
                    style={nord}
                    wrapLines={true}
                    wrapLongLines={true}
                  >
                    {`# Pandas Translation\nimport pandas as pd\ndf = pd.read_csv("<CSV_FILE_PATH>")\n\n` +
                      pandas_answer}
                  </SyntaxHighlighter>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={copy_pandas_answer}
                  >
                    Copy to Clipboard
                  </Button>
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="success">
                      🎉 Pandas Code was successfully copied to your clipboard!
                    </Alert>
                  </Snackbar>
                </Box>
              </TabPanel>
            </TabContext>
            {/* Box should have width of 100% */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DatasetPlayground;
