// Kiko API Functions.
// API Url:  https://kikocrypto.ngrok.io

// Import axios
const axios = require("axios");

// const kiko_api_url = "https://kikocrypto.ngrok.io";
const kiko_api_url = "https://api.kikocode.com";

/**
 * Kiko API Health Check
 * @returns {Promise} Promise
 */
// const kiko_api_health_check = () => {
//   return axios.get(`${kiko_api_url}/health`);
// };

/**
 * @typedef SQLTranslation
 * @property {string} status - Status of the translation
 * @property {string} sql_translation  - The translation itself.
 */

/**
 * @typedef PandasTranslation
 * @property {string} status - Status of the translation
 * @property {string} pandas_translation  - The translation itself.
 */

/**
 * Translate Query
 * @param {string} query
 * @param {Array<string>} dataset_ids
 * @param {string} api_key
 * @param {boolean} dev_mode
 * @param {string} auth_token
 * @returns {Promise<SQLTranslation>} A promise of a translation.
 * @example translate_query("who made the most money?","123235","LKSDJFOIWEJOTI")
 */
export const translate_query = (
  query,
  dataset_ids,
  api_key,
  dev_mode,
  auth_token
) => {
  if (dev_mode) {
    return Promise.resolve({
      sql_translation:
        "SELECT name FROM employees WHERE salary = (SELECT MAX(salary) FROM employees)",
      status: "successful",
    });
  }

  var config = {
    method: "post",
    data: {
      query: query,
      dataset_ids: dataset_ids,
      api_key: api_key ? api_key : "",
    },
    headers: {
      authorization: auth_token ? auth_token : "",
    },
    url: `${kiko_api_url}/dataset/translate_query`,
  };

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", sql_translation: "Error, please try again." };
    }
  );
};

/**
 * Translate Query to Pandas
 * @param {string} query
 * @param {string} dataset_id
 * @param {string} api_key
 * @returns {Promise<PandasTranslation>} A promise of a translation.
 * @example translate_query("who made the most money?","123235","LKSDJFOIWEJOTI")
 */
export const translate_query_pandas = (
  query,
  dataset_id,
  api_key,
  dev_mode,
  auth_token
) => {
  if (dev_mode) {
    return Promise.resolve({
      pandas_translation:
        "SELECT name FROM employees WHERE salary = (SELECT MAX(salary) FROM employees)",
      status: "successful",
    });
  }

  var config = {
    method: "get",
    params: {
      query: query,
      dataset_id: dataset_id,
      api_key: api_key ? api_key : "",
    },
    headers: {
      authorization: auth_token ? auth_token : "",
    },
    url: `${kiko_api_url}/dataset/translate_query/pandas`,
  };

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", sql_translation: "Error, please try again." };
    }
  );
};

// User API Functions

/**
 * @typedef Dataset
 * @property {string} dataset_id
 * @property {string} dataset_name
 * @property {string} table_description
 * @property {string} dataset_type
 * @property {string} table_name
 * @property {string} user_id
 * @property {Array<Column>} columns // optional
 */
/**
 * @typedef Column
 * @property {string} column_name
 * @property {string} column_type
 * @property {string} column_example
 * @property {string} column_id
 */

/**
 * Get User Datasets
 * @returns {Promise<{"user_datasets":Array<Dataset>}>} A promise of an array of datasets.
 * @param {string} auth_token
 * @example get_user_datasets("aowegjoiaw")
 */
export const get_user_datasets = (auth_token) => {
  var config = {
    method: "get",
    url: `${kiko_api_url}/user/datasets/list`,
    headers: { authorization: auth_token },
  };
  // console.log(auth_token);

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not access datasets." };
    }
  );
};

/**
 * Get User Info
 * @returns {Promise<{"name":string, "stripe_id":string}>} A promise of an array of datasets.
 * @param {string} auth_token
 * @example get_user_general("aowegjoiaw")
 */
export const get_user_general = (auth_token) => {
  var config = {
    method: "get",
    url: `${kiko_api_url}/user/info`,
    headers: { authorization: auth_token },
  };
  // console.log(auth_token);

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not access datasets." };
    }
  );
};

/**
 * Get Dataset Info
 * @returns {Promise<Dataset>} A promise of a dataset.
 * @param {string} dataset_id
 * @param {string} auth_token
 * @example get_dataset_info("123235")
 */
export const get_dataset_info = (dataset_id, auth_token) => {
  var config = {
    method: "get",
    url: `${kiko_api_url}/dataset/info?dataset_id=${dataset_id}`,
    headers: {
      authorization: auth_token,
    },
  };

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not access dataset." };
    }
  );
};

/**
 * Get Dataset Info
 * @returns {Promise<{"status":"successful","logs":[{"answer":string,"dataset_id":string,"event_type":string,"language":string,"query":string,"timestamp":string,"user_id":string}]}>} A promise of a dataset.
 * @param {string} dataset_id
 * @param {string} auth_token
 * @example get_dataset_info("123235")
 */
export const get_dataset_logs = (dataset_id, auth_token) => {
  var config = {
    method: "get",
    url: `${kiko_api_url}/dataset/logs`,
    params: {
      dataset_id: dataset_id,
    },

    headers: {
      authorization: auth_token,
    },
  };

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not access dataset." };
    }
  );
};

/**
 * Update Dataset Info
 * @returns {Promise<{"message": "Dataset updated successfully."}>} A promise of a string.
 * @param {Dataset} dataset
 * @param {string} auth_token
 */
export const update_dataset_info = (dataset, auth_token) => {
  var config = {
    method: "post",
    url: `${kiko_api_url}/dataset/update`,
    params: {
      dataset_id: dataset.dataset_id,
    },
    headers: {
      authorization: auth_token,
    },
    data: dataset,
  };

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not update dataset." };
    }
  );
};

/**
 * Create Dataset
 * @returns {Promise<{"message": "Dataset created successfully."}>} A promise of a string.
 * @param {Dataset} dataset
 * @param {string} auth_token
 */
export const create_dataset = (dataset, auth_token) => {
  var config = {
    method: "post",
    url: `${kiko_api_url}/dataset/create`,
    // params: {
    //   dataset_id: dataset.dataset_id,
    // },
    headers: {
      authorization: auth_token,
    },
    data: dataset,
  };

  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not create dataset." };
    }
  );
};

/**
 * Delete Dataset
 * @returns {Promise<{"message": "Dataset deleted successfully."}>} A promise of a string.
 * @param {String} dataset_id
 * @param {string} auth_token
 */
export const delete_dataset = (dataset_id, auth_token) => {
  var config = {
    method: "get",
    url: `${kiko_api_url}/dataset/delete`,
    params: {
      dataset_id: dataset_id,
    },
    headers: {
      authorization: auth_token,
    },
  };
  return axios(config).then(
    function (response) {
      return response.data;
    },
    function (error) {
      return { status: "failed", message: "Error, could not delete dataset." };
    }
  );
};

/**
 * Create User
 * @returns {Promise<{"message": "User created successfully."}>} A promise of a string.
 * @param {string} auth_token
 */
export const create_user = (auth_token) => {
  var config = {
    method: "get",
    url: `${kiko_api_url}/user/create`,
    headers: {
      authorization: auth_token,
    },
  };

  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

// API TESTS

// KIKO API HEALTH CHECK
// kiko_api_health_check().then((response) => {
//   console.log(response);
// });

// KIKO API TRANSLATE QUERY
// translate_query(
//   "who made the most money?",
//   "ZMQQNNDI2",
//   "QYLTEFYNOZDXVSHSNMKKE8TYB"
// ).then((response) => {
//   console.log(response);
// });
