import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "../util/router";
import AddDatasetButton from "./AddDatasetButton";
import { get_user_datasets } from "../util/kiko_api";
import { useEffect } from "react";
import { useAuth } from "../util/auth";
import FadeIn from "react-fade-in";

/**
 * @typedef Dataset
 * @property {string} dataset_id
 * @property {string} dataset_name
 * @property {string} table_description
 * @property {string} dataset_type
 * @property {string} table_name
 * @property {string} user_id
 */
function DatasetsGrid(props) {
  const auth = useAuth();

  /**
   * An array of datasets.
   * @type {[Array<Dataset>,Function]} datasets
   */
  const [datasets, setDatasets] = React.useState([]);

  //create pending variable
  const [pending, setPending] = React.useState(true);
  useEffect(() => {
    var auth_token = auth.user.accessToken;
    setPending(true);
    get_user_datasets(auth_token).then((data) => {
      // Check if user_datasets in data
      if (data.user_datasets) {
        setDatasets(data.user_datasets);
        setPending(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container={true} justifyContent="center" spacing={4}>
          {/* Show if datasets is null */}
          {pending && (
            <Grid item={true} xs={12} md={6} lg={3}>
              <FadeIn>
                <Card>
                  <CardActionArea>
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom={true}
                      >
                        Loading your datasets...
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </FadeIn>
            </Grid>
          )}
          {datasets.map((dataset, index) => (
            <Grid item={true} xs={12} md={6} lg={3} key={index}>
              <FadeIn>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/dataset/` + dataset.dataset_id}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom={true}
                      >
                        {dataset.dataset_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {dataset.table_description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </FadeIn>
            </Grid>
          ))}

          <AddDatasetButton
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            title=""
            subtitle=""
            buttonText="+ Add Dataset"
            buttonColor="primary"
            buttonPath="/add-dataset"
          />
        </Grid>
      </Container>
    </Section>
  );
}

export default DatasetsGrid;
