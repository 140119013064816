import React from "react";

import { TextField } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useEffect } from "react";
import { useAuth } from "./../util/auth";
import { get_user_datasets } from "../util/kiko_api";
import CircularProgress from "@material-ui/core/CircularProgress";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * @typedef Dataset
 * @property {string} dataset_id
 * @property {string} dataset_name
 * @property {string} table_description
 * @property {string} dataset_type
 * @property {string} table_name
 * @property {string} user_id
 */

function IncludeDatasets(props) {
  const auth = useAuth();

  const formik = props.props;
  const dataset_id = props.dataset_id;

  const [pending, setPending] = React.useState(false);
  /**
   * An array of datasets.
   * @type {[Array<Dataset>,Function]} datasets
   */
  const [datasets, setDatasets] = React.useState([]);

  useEffect(() => {
    var auth_token = auth.user.accessToken;

    setPending(true);

    get_user_datasets(auth_token).then((data) => {
      var user_datasets = data.user_datasets;
      // Remove item from user_datasets if it has dataset_id
      if (dataset_id) {
        user_datasets = user_datasets.filter(
          (dataset) => dataset.dataset_id !== dataset_id
        );
      }
      if (user_datasets) {
        setDatasets(user_datasets);
        setPending(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDatasetsChange = (event, value) => {
    var included_dataset_ids = value.map((dataset) => {
      return dataset.dataset_id;
    });
    // Add dataset_id to included_dataset_ids
    included_dataset_ids.push(dataset_id);
    console.log(included_dataset_ids);
    formik.setFieldValue("dataset_ids", included_dataset_ids);
  };

  return (
    <div>
      {pending && <CircularProgress />}
      {!pending && (
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={datasets}
          disableCloseOnSelect
          onChange={handleDatasetsChange}
          getOptionLabel={(dataset) => dataset.dataset_name}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.dataset_name}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Your Datasets"
              placeholder="Select the datasets you want to include..."
              style={{ width: 500 }}
            />
          )}
        />
      )}
    </div>
  );
}

// function IncludeDatasets(props) {
//   return <CheckboxesTags props={props}></CheckboxesTags>;
// }

export default IncludeDatasets;
