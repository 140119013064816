import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Button, Card } from "@material-ui/core";
import { get_dataset_info } from "./../util/kiko_api";
import { useEffect } from "react";
import { useAuth } from "./../util/auth";
import FadeIn from "react-fade-in";
import DatasetPlayground from "./DatasetPlayground";
import DatasetInfo from "./DatasetInfo";
import DatasetLogs from "./DatasetLogs";
import { Link } from "./../util/router";

/**
 * @typedef Dataset
 * @property {string} dataset_id
 * @property {string} dataset_name
 * @property {string} table_description
 * @property {string} dataset_type
 * @property {string} table_name
 * @property {string} user_id
 * @property {Array<String>} generated_questions
 * @property {Array<Column>} columns // optional
 */
/**
 * @typedef Column
 * @property {string} column_name
 * @property {string} column_type
 * @property {string} column_example
 * @property {string} column_id
 */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {/* Make the Box have a max width of 300px */}
      {value === index && (
        <Box style={{ maxWidth: 800 }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 600,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiSelect-outlined": {
      margin: theme.spacing(0.5),
      // minWidth: 120,
      // height: 10,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function DatasetSection(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const auth = useAuth();

  const dataset_id = props.dataset_id;

  /**
   * @typedef {Dataset} dataset_info
   * @description A dataset object
   */
  /**
   * @typedef {Function} setDatasetInfo
   * @description A function that sets the dataset info
   */
  /**
   * @type {[dataset_info, setDatasetInfo]} dataset_info
   */
  const [dataset_info, setDatasetInfo] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    var auth_token = auth.user.accessToken;

    get_dataset_info(dataset_id, auth_token).then((data) => {
      setDatasetInfo(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        {/* Add button that routes back to /dashboard */}
        <Button component={Link} to={"/dashboard"}>
          ⬅️ View All Datasets
        </Button>
        {/* Show when dataset_info is not null */}
        {dataset_info && (
          <FadeIn>
            <SectionHeader
              title={dataset_info.dataset_name}
              subtitle={dataset_info.table_description}
              size={4}
              textAlign="center"
              //Add margin to the bottom
              marginBottom={5}
            />
          </FadeIn>
        )}
        {!dataset_info && (
          <SectionHeader
            title="Loading Dataset..."
            subtitle="Loading Dataset..."
            size={4}
            textAlign="center"
            marginBottom={5}
          />
        )}
        <Grid container={true} justifyContent="center" spacing={4}>
          <Card
            style={{
              width: "100%",
            }}
            elevation={20}
          >
            <div className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
              >
                <Tab label="Playground" {...a11yProps(0)} />
                <Tab label="Dataset Info" {...a11yProps(1)} />

                <Tab label="Generated Questions" {...a11yProps(1)} />

                <Tab label="Logs" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={value} index={0} style={{ width: "100%" }}>
                {dataset_info && (
                  <DatasetPlayground
                    dataset_info={dataset_info}
                  ></DatasetPlayground>
                )}
              </TabPanel>
              <TabPanel value={value} index={1} style={{ overflowY: "scroll" }}>
                {dataset_info && (
                  <div>
                    <DatasetInfo dataset_id={dataset_id}></DatasetInfo>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={2} style={{ overflowY: "scroll" }}>
                {/* Header */}
                <div>
                  <SectionHeader
                    title="Generated Questions"
                    subtitle="Here are some questions that Kiko thinks you should ask your dataset! These questions were generated using GPT-3."
                    size={5}
                    textAlign="left"
                    marginBottom={5}
                  />
                </div>
                {dataset_info?.generated_questions ? (
                  <div>
                    {dataset_info.generated_questions.map((question, index) => {
                      return <p key={index}>- {question}</p>;
                    })}
                  </div>
                ) : (
                  <div>
                    <p>No questions were generated.</p>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <DatasetLogs dataset_id={dataset_id} />
              </TabPanel>
            </div>
          </Card>
        </Grid>
      </Container>
    </Section>
  );
}

export default DatasetSection;
