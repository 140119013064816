import React, { useEffect, useState } from "react";
import PageLoader from "./PageLoader";
import { useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { redirectToBilling } from "./../util/stripe";
import { get_user_general } from "./../util/kiko_api";

function SettingsBilling(props) {
  const router = useRouter();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user.planIsActive) {
      console.log(auth.user.accessToken);
      get_user_general(auth.user.accessToken).then((response) => {
        redirectToBilling(response.stripe_id).catch((error) => {
          setLoading(false);
          props.onStatus({
            type: "error",
            message: error.message,
          });
        });
      });
    } else {
      // Otherwise go to pricing so they can
      // purchase a plan
      router.replace("/pricing");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{loading && <PageLoader height={50} />}</>;
}

export default SettingsBilling;
