import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useAuth } from "./../util/auth";
import { get_dataset_logs } from "../util/kiko_api";
import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

/**
 * @typedef Log
 * @property {string} answer
 * @property {string} dataset_id
 * @property {string} event_type
 * @property {string} language
 * @property {string} query
 * @property {string} timestamp
 * @property {string} user_id
 */

export default function DatasetLogs(props) {
  const classes = useStyles();
  const auth = useAuth();

  const dataset_id = props.dataset_id;

  /**
   * @typedef {Array<Log>} logs
   * @description An array of log objects
   */
  /**
   * @typedef {Function} setLogs
   * @description A function that sets logs
   */
  /**
   * @type {[logs, setLogs]} logs
   */
  const [logs, setLogs] = React.useState();

  useEffect(() => {
    var auth_token = auth.user.accessToken;

    get_dataset_logs(dataset_id, auth_token).then((data) => {
      var logs = data.logs;

      setLogs(logs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {logs ? (
        <TableContainer
          component={Paper}
          style={{ overflowY: "scroll", maxHeight: 500 }}
        >
          <Table stickyHeader className={classes.table} aria-label="logs">
            <TableHead>
              <TableRow>
                {/* Create cells from Log schema */}
                <TableCell>Timestamp</TableCell>

                <TableCell>Answer</TableCell>
                <TableCell>Event Type</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Query</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.timestamp}
                  </TableCell>
                  <TableCell align="right">{row.answer}</TableCell>
                  <TableCell align="right">{row.event_type}</TableCell>
                  <TableCell align="right">{row.language}</TableCell>
                  <TableCell align="right">{row.query}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {logs.length === 0 && <p>There are no logs for this dataset.</p>}
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
